import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-festivals',
  templateUrl: './festivals.component.html',
  styleUrls: ['./festivals.component.css']
})
export class FestivalsComponent implements OnInit {

  constructor() { }

  imagesPath = 'festivals';
  seriesText = 'test';
  backgroundImage = '../../../../assets/background-festivals.jpg';


  ngOnInit(): void {
  }

}
