<app-load-spinner *ngIf="showSpinner"></app-load-spinner>

<ngb-carousel *ngIf="showImages"
              interval="10000"
              pauseOnHover="true"
              [showNavigationIndicators]="false"
              class="home-carousel"
              >
  <ng-template *ngFor="let image of carouselImages" ngbSlide>
    <img class="carousel-image"
         src="{{image.url}}"
         alt="Random slide"
         (load)="spinnerDissapears()">
  </ng-template>
</ngb-carousel>
