<app-load-spinner *ngIf="showSpinner"></app-load-spinner>
<div id="page-content" *ngIf="showImages" class="container-fluid ">
  <div class="series-description  col-md-5 col-lg-5  edgy-font">
    {{seriesText}}
  </div>
  <div class="image-panel  col-md-7 col-lg-7">
    <a href="" (click)="selectFullSizeImage(idx);logFullImageClick(portfolioImage)"
       *ngFor="let portfolioImage of images; index as idx"
       data-toggle="modal"
       data-target="#fullImageModal"
    >
      <img *ngIf="portfolioImage.name!==''"
           class="image-thumbnail"
           src="{{portfolioImage.url_medium}}"
           alt="fuck"
           (load)="spinnerDissapears()">
    </a>
  </div>
</div>

<div class="modal hide fade"
     id="fullImageModal"
     tabindex="-1"
     role="document"
     aria-labelledby="fullImageModal"
     aria-hidden="true"
     *ngIf="fullImageIndex!==-1"
>
  <div class="modal-content">
    <div class="modal-dialog" role="dialog">
      <div class="spinner-container">
        <app-full-image-spinner *ngIf="showSlideshowSpinner"></app-full-image-spinner>
      </div>
      <img (load)="setImageType(images[fullImageIndex])" id="currentFullSizeImg"
           [src]="images[fullImageIndex].url_large"
           [ngStyle]="{'opacity': showSlideshowSpinner ? '0' : '1' }"
           srcset="{{images[fullImageIndex].url_large}} {{fullImageSize}}"
           alt="fuck squared">
    </div>
    <div class="image-buttons">
      <a class="icon"
         *ngIf="fullImageIndex > 0"
         (click)="getNextPicture(-1)">
        <i class="fas fa-chevron-left"></i>
      </a>

      <a class="icon"
         *ngIf="fullImageIndex<images.length-1"
         (click)="getNextPicture(1)">
        <i class="fas fa-chevron-right"></i>
      </a>
    </div>
  </div>
</div>

